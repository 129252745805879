import React, { useEffect, useState, useRef } from 'react';
import LoadSpinner from './LoadSpinner';
import Subtitle from './subtitles.vtt';

//images
import BENTOHUB_LOGO from '../images/BENTOHUB_LOGO.png'

import { FaPlay } from "react-icons/fa";
import { FaPause } from "react-icons/fa6";
import { ImVolumeMute2 } from "react-icons/im";
import { ImVolumeLow } from "react-icons/im";
import { ImVolumeMedium } from "react-icons/im";
import { FaClosedCaptioning } from "react-icons/fa";
import { MdPictureInPictureAlt } from "react-icons/md";
import { RiPictureInPictureExitFill } from "react-icons/ri";
import { TbRectangle } from "react-icons/tb";
import { TbRectangleFilled } from "react-icons/tb";
import { RiFullscreenFill } from "react-icons/ri";
import { RiFullscreenExitFill } from "react-icons/ri";
import { IoMdSettings } from "react-icons/io";
import { TbClockPlay } from "react-icons/tb";
import { LuSettings2 } from "react-icons/lu";
import { IoIosArrowForward } from "react-icons/io";
import { IoIosArrowBack } from "react-icons/io";
import { MdOutlineReplay10 } from "react-icons/md";
import { MdForward10 } from "react-icons/md";
import { FaListUl } from "react-icons/fa";

import Hls from 'hls.js';
import '../styles.css';

const SeekableRange = React.memo(({ seekableRange }) => {
  return (
    <>
      {seekableRange && seekableRange.map((range, index) => (
        <div
          key={index}
          style={{
            left: `${range.startPercentage}%`,
            width: `${range.totalWidth}px`,
            right: `${range.endPercentage}%`,
            backgroundColor: '#969696',
            position: 'absolute',
            top: 0,
            bottom: 0,
          }}
        >
        </div>
      ))}
    </>
  );
});

const VideoPlayer = ({ 
  src,
  customControls,
  width,
  height,
  videoData,
  isLoading,
  setIsLoading,
  timeChaptersData, 
}) => {
  const { removeControls, loop, autoplay, muted, startAt } = customControls

  const videoRef = useRef(null);
  const videoContainerRef = useRef(null);
  const thumbnailImgRef = useRef(null);
  const previewImgRef = useRef(null);
  const timelineContainerRef = useRef(null)
  const lastTouchRef = useRef(null);
  // const [isLoading, setIsLoading] = useState(true);
  const [initialPlay, setInitialPlay] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMiniPlayer, setIsMiniPlayer] = useState(false);
  const [isTheater, setIsTheater] = useState(false);

  const [isMuted, setIsMuted] = useState(false);
  const [isVolumeHigh, setIsVolumeHigh] = useState(true);
  const [isVolumeLow, setIsVolumeLow] = useState(false);
  const [volume, setVolume] = useState(1);
  const [showVolume, setShowVolume] = useState(false);

  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isPlaybackRateOpen, setIsPlaybackRateOpen] = useState(false);
  const [isClosedCaptionOpen, setIsClosedCaptionOpen] = useState(false);
  const [isQualityOpen, setIsQualityOpen] = useState(false);
  const [isChapterListOpen, setIsChapterListOpen] = useState(false);

  const [playbackRate, setPlaybackRate] = useState(1);
  const [playbackRateArr, setPlaybackRateArr] = useState([ 0.25, 0.50, 0.75, 1, 1.25, 1.50, 1.75, 2]);

  const [isCaptions, setIsCaptions] = useState(false);
  const [captionIndex, setCaptionIndex] = useState(0);

  const [isScrubbing, setIsScrubbing] = useState(false);
  const [imageError, setImageError] = useState(false);

  const [currentTime, setCurrentTime] = useState("");
  const [totalTime, setTotalTime] = useState("");

  const [seekableRange, setSeekableRange] = useState(null);

  const [hideControls, setHideControls] = useState(false);

  const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
  const [qualityLevels, setQualityLevels] = useState([]);
  const [currentQuality, setCurrentQuality] = useState(`${videoDimensions.height}p`);
  const [currentLevel, setCurrentLevel] = useState(-1);

  const [timeChapters, setTimeChapters] = useState(timeChaptersData? timeChaptersData : []);

  let hls = new Hls({
      maxBufferLength: 600,  // 10 minutes
      maxMaxBufferLength: 1200,  // 20 minutes
      maxBufferSize: 30 * 1000 * 1000,  // 30 MB
      liveSyncDurationCount: 3,
      liveMaxLatencyDurationCount: 10,
      autoLevelEnabled: false
  });

  useEffect(() => {
    const video = videoRef.current;
    const requestStartTimes = new Map(); // Map to track request start times
    if (Hls.isSupported() && src && src.endsWith('.m3u8')) {
        hls = new Hls({
            maxBufferLength: 600,  // 10 minutes
            maxMaxBufferLength: 1200,  // 20 minutes
            maxBufferSize: 30 * 1000 * 1000,  // 30 MB
            liveSyncDurationCount: 3,
            liveMaxLatencyDurationCount: 10,
            autoLevelEnabled: false
        });

        hls.loadSource(src);
        hls.attachMedia(video);

        hls.currentLevel = currentLevel
        video.currentTime = currentTime

    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = src;
    } else {
      video.src = src;
    }

    return () => {
        if (hls) {
            hls.destroy();
        }
    };
  }, [src, currentLevel]);

  useEffect(() => {
      const interval = setInterval(() => {
          if (videoRef.current) {
              setCurrentTime(videoRef.current.currentTime);
          }
      }, 1000);

      return () => clearInterval(interval);
  }, []);

  const togglePlay = (e) => {
    e.stopPropagation();
    if (videoRef.current.paused) {
        videoRef.current.play();
        setIsPlaying(true);
    } else {
        videoRef.current.pause();
        setIsPlaying(false);
    }
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);

    const video = videoContainerRef.current;
    if (!document.fullscreenElement) {
      video.requestFullscreen().catch((err) => {
        console.error('Failed to enter full screen:', err);
      });
    } else {
      document.exitFullscreen();
    }
  }

  const toggleTimeChapterList = () => {
    setIsChapterListOpen(!isChapterListOpen)
    setIsSettingsOpen(false);
  }

  const toggleMiniPlayer = () => {
    const video = videoRef.current;
    if (!document.pictureInPictureElement) {
      setIsMiniPlayer(true);
      video.requestPictureInPicture().catch((err) => {
        console.error('Failed to enter Picture-in-Picture mode:', err);
      });
    } else {
      setIsMiniPlayer(false);
      document.exitPictureInPicture().catch((err) => {
        console.error('Failed to exit Picture-in-Picture mode:', err);
      });
    }
  };

  const toggleTheater = () => {
    setIsTheater(!isTheater);
  }

  const toggleMute = () => {
    const currentMutedState = videoRef.current.muted;
    videoRef.current.muted = !currentMutedState;
    volumeChecker();
  };

  const hideCaptions = () => {
    const video = videoRef.current
    const captions = video.textTracks[captionIndex]
    captions.mode ="hidden"
    setIsCaptions(false);
    // NOTE TO ME: Delete this if its ok!!!
    // setIsCaptions(captions.mode === "hidden");
    // const isHidden = captions.mode === "hidden"
    // captions.mode = isHidden ? "showing" : "hidden"
  }

  const changeCaption = (index) => {
    const video = videoRef.current
    const captions = video.textTracks[index]
    setCaptionIndex(index)
    setIsCaptions(true)
    captions.mode ="showing"
  }

  const handleLoadData = () => {
    setTotalTime(videoRef.current.duration)
  };

  const handleTimeUpdate = async () => {
    const video = videoRef.current;

    setCurrentTime(video.currentTime);
    let percent = video.currentTime / video.duration;
    if(!percent){
      percent = 0
    }
    timelineContainerRef.current.style.setProperty("--progress-position", percent);

  };

  const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
      minimumIntegerDigits: 2
  });

  function formatDuration(time){
    const seconds = Math.floor(time % 60);
    const minutes = Math.floor(time / 60) % 60;
    const hours = Math.floor(time / 3600);
    if(hours === 0){
      return `${minutes}:${leadingZeroFormatter.format(seconds)}`
    } else {
      return `${hours}:${minutes}:${leadingZeroFormatter.format(seconds)}`
    }
  }

  function skip(duration) {
    videoRef.current.currentTime += duration
  }

  function alterCurrentTime(newTime) {
    videoRef.current.currentTime = newTime
  }

  const toggleScrubbing = async (e, isStartScrubbing) => {
    const isTouchEvent = e.type.includes("touch");
    console.log("Touching?")
    // For mouse events, ensure only the left mouse button is pressed
    if (e.button !== 0 && !isTouchEvent) {
      return;
    }

    // Check if it's a touchend event and set scrubbing to false
    let scrubbing;
    if (e.type === "touchend") {
      scrubbing = false; // Stop scrubbing on touchend
    } else {
      scrubbing = (e.buttons & 1) === 1 || (isTouchEvent && isStartScrubbing);
    }

    const video = videoRef.current;
    const timelineContainer = timelineContainerRef.current;
    const rect = timelineContainer.getBoundingClientRect();

    let Ex;
    if (isTouchEvent) {
      if (e.type === "touchend") {
        // Use the last known touch position on touchend
        Ex = lastTouchRef.current;
      } else if (e.touches.length > 0) {
        Ex = e.touches[0].clientX; // Use `touches[0].clientX` for other touch events
        lastTouchRef.current = Ex; // Update the last known touch position
      }
    } else {
      Ex = e.clientX; // Use the regular event clientX for mouse
    }

    if (Ex === undefined) return;

    const percent = Math.min(Math.max(0, Ex - rect.x), rect.width) / rect.width;

    setIsScrubbing(scrubbing);

    try {
      if (scrubbing) {
        video.pause();
      } else {
        video.currentTime = percent * video.duration;
        if (isPlaying) await video.play();
      }

      handleTimelineUpdate(e);
    } catch (error) {
      console.error("Error toggling scrubbing:", error);
    }
  };

  async function handleTimelineUpdate(e, isHovered) {
    const video = videoRef.current;

    const isTouchEvent = e.type.includes("touch");
    const videoDuration = videoRef.current.duration;
    const rect = timelineContainerRef.current.getBoundingClientRect();
    let Ex;
    if (isTouchEvent) {
      Ex = e.touches[0]?.clientX; // Use `touches[0].clientX` for touch events
    } else if (isHovered) {
      Ex = e.nativeEvent.x; // Use native event x for hover
    } else {
      Ex = e.x; // Use the regular event x for mouse
    }

    if (Ex === undefined) return;

    const percent = Math.min(Math.max(0, Ex - rect.x), rect.width) / rect.width
    const previewImgNumber = Math.max(1, Math.floor((percent * videoDuration) / 10));
    const previewImgSrc =  videoData.scrubbingImages[previewImgNumber]
    previewImgRef.current.src = previewImgSrc;

    timelineContainerRef.current.style.setProperty("--preview-position", percent);
    if(isScrubbing){
      // e.preventDefault();
      thumbnailImgRef.current.src = videoData.scrubbingImages[previewImgNumber]
      timelineContainerRef.current.style.setProperty("--progress-position", percent);
    }

    if (e.type === "touchstart") {
      video.currentTime = await percent * video.duration;
      await timelineContainerRef.current.style.setProperty("--progress-position", percent);
      lastTouchRef.current = Ex; 
      if (isPlaying) await video.play();
    }
  }
  const handleVolumeChange = (e) => {
    const value = e.target.value;
    setVolume(value);
    videoRef.current.volume = value;
    volumeChecker();
  };

  const handlePlaybackRateChange = (speed) => {
      // const newPlaybackRate = playbackRate >= 2 ? 0.25 : playbackRate + 0.25;
      setPlaybackRate(speed);
      videoRef.current.playbackRate = speed;
  };

  const handleSeeking = async () => {
    setIsLoading(true)
    await handleBuffered();
  };

  const handleBuffered = async () => {
    const bufferedRanges = [];
    const { buffered } = videoRef.current;

    for (let i = 0; i < buffered.length; i++) {
      bufferedRanges.push([buffered.start(i), buffered.end(i)]);
    }

    setIsLoading(false);

    const bufferedPercentages = await calculateSeekableRangePercentages(bufferedRanges);
    setSeekableRange(bufferedPercentages);
  };

  const changeResolution = (levelIndex, label, url) => {
      hls.currentLevel = levelIndex;
      setCurrentQuality(label);
      setCurrentLevel(levelIndex);
  }

  // helper functions
  const volumeChecker = () => {
    const volume = videoRef.current.volume;
    const muted = videoRef.current.muted;
    const isMuted = muted || volume === 0;

    setIsMuted(isMuted);
    setIsVolumeHigh(volume > 0.5 && !isMuted);
    setIsVolumeLow(volume < 0.5 && !isMuted);
  };

  const handleMetadataLoaded = () => {
      if (videoRef.current) {
          setVideoDimensions({
              width: videoRef.current.videoWidth,
              height: videoRef.current.videoHeight
          });
      }
  };

  useEffect(() => {
    if (hls) {
      hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const qualityLevels = hls.levels.map((level, index) => ({
          label: `${level.width > level.height? level.height : level.width}p`,
          value: index,
          url: level.url[0]
        }));

        const uniqueQualityLevels = qualityLevels.filter((level, index, self) =>
          index === self.findIndex((t) => (
            t.url === level.url
          ))
        );

        setCurrentQuality(`${hls.currentLevel}p`);
        setQualityLevels(uniqueQualityLevels);
      });
    }
  }, [hls]);

  useEffect(() => {
    const handleExitPictureInPicture = () => {
      setIsMiniPlayer(false)
    };
    document.addEventListener("leavepictureinpicture", handleExitPictureInPicture);

    return () => {
      document.removeEventListener("leavepictureinpicture", handleExitPictureInPicture);
    };
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    const handleEnded = () => {
      setIsPlaying(false)
    };

    video.addEventListener('ended', handleEnded);

    return () => {
      video.removeEventListener('ended', handleEnded);
    };
  }, []);

  useEffect(() => {
    const handleFullscreenChange = () => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);
      }
    };
    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
      let hideControlsTimer;

      const handleMouseUp = (e) => {
          if (isScrubbing) {
              toggleScrubbing(e);
          }
      };

      const handleMouseMove = (e) => {
          if (isScrubbing) {
              handleTimelineUpdate(e);
          }
          clearTimeout(hideControlsTimer);
          setHideControls(false);
          hideControlsTimer = setTimeout(() => {
              if(!isSettingsOpen || !isPlaying){
                setHideControls(true);
              }
          }, 5000);
      };

      const handleMouseActivity = () => {
          clearTimeout(hideControlsTimer);
          setHideControls(false);
          hideControlsTimer = setTimeout(() => {
              if(!isSettingsOpen || !isPlaying){
                setHideControls(true);
              }
          }, 5000);
      };

      document.addEventListener("mouseup", handleMouseUp);
      document.addEventListener("mousemove", handleMouseMove);
      // document.addEventListener("mouseenter", handleMouseActivity);
      // document.addEventListener("mouseleave", handleMouseActivity);

      return () => {
          document.removeEventListener("mouseup", handleMouseUp);
          document.removeEventListener("mousemove", handleMouseMove);
          // document.removeEventListener("mouseenter", handleMouseActivity);
          // document.removeEventListener("mouseleave", handleMouseActivity);
          clearTimeout(hideControlsTimer);
      };
  }, [isScrubbing, isSettingsOpen, isPlaying]);

  useEffect(() => {
      const video = videoRef.current;
      const track = video.textTracks[0];

      const handleVideoLoad = () => {
          track.mode = "hidden";
      };

      video.addEventListener("loadeddata", handleVideoLoad);

      return () => {
          video.removeEventListener("loadeddata", handleVideoLoad);
      };
  }, []);

  useEffect(() => {
    if(startAt){
      skip(+startAt)
    }
  }, [customControls])

  useEffect(() => {
    console.log(timeChaptersData)
    setTimeChapters(timeChaptersData)
  },[timeChaptersData])

  const handleLoadingStart = () => {
    setIsLoading(true);
  };

  const handleLoadingComplete = () => {
    setIsLoading(false);
  };

  const calculateSeekableRangePercentages = async (bufferedRanges) => {
    return new Promise((resolve, reject) => {
      try {
        const timelineContainer = timelineContainerRef.current;
        const rect = timelineContainer.getBoundingClientRect();
        const timelineContainerWidth = rect.width;

        const totalDuration = videoRef.current.duration;
        const percentages = bufferedRanges.map(range => {
          const startTime = range[0];
          const endTime = range[1];
          const startPercentage = Number(((startTime / totalDuration) * 100).toFixed(2));
          const endPosition = Number(((endTime / totalDuration) * 100).toFixed(2));
          const endPercentage = Number((endPosition - 100) * -1);
          const totalWidth = Number(((endPosition - startPercentage) * timelineContainerWidth / 100).toFixed(2));
          return { startPercentage, endPercentage, totalWidth };
        });
        resolve(percentages);
      } catch (error) {
        reject(error);
      }
    });
  };

  return (
    <div className={`relative w-full pt-[56.25%] ${isLoading? "pointer-events-none" : "nonSelectableText"}`}>
      <div className="absolute top-0 bottom-0 flex place-content-center bg-[#000000] w-full h-full">
        <div ref={videoContainerRef} 
          className={`
          video-container
          overflow-auto
          ${isTheater? "theater" : ""} 
          ${isCaptions? "captions" : ""} 
          ${isScrubbing? "scrubbing" : ""} 
          ${isLoading? "loading" : ""} 
          ${isPlaying? "" : "paused"}
          `}
        >
            {
              isLoading?
              <div className="absolute top-[40%]">
                <LoadSpinner isLoading={isLoading} />
              </div>
              :
              null
            }
            <div className={`${removeControls? "hidden" : ""} absolute flex min-h-full min-w-full z-[40]`} onClick={togglePlay}>
              <div className={`video-controls-container flex min-h-full min-w-full ${hideControls || isLoading? "hidden" : ""}`}>
                <div className="w-1/3 flex items-center justify-end text-white">
                  <MdOutlineReplay10 
                    className="w-10 h-10 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation()
                      skip(-10)
                    }}
                  />
                </div>
                <div className="w-1/3 flex items-center justify-center text-white">
                  {
                    isPlaying ? 
                    <FaPause className="w-12 h-12"/> 
                    : 
                    <FaPlay className="w-12 h-12"/>
                  }
                </div>
                <div className="w-1/3 flex items-center justify-start text-white">
                  <button
                    onClick={(e) => {
                      e.stopPropagation()
                      skip(10)
                    }}
                  >
                  <MdForward10 
                    className="w-10 h-10 cursor-pointer"
                  />
                  </button>
                </div>
              </div>
            </div>

            <div className="thumbnail-img bg-[#000000] place-content-center">
              <img ref={thumbnailImgRef}
                className={`${imageError? "opacity-0" : "opacity-100"}`} 
                onError={() => setImageError(true)}
                onLoad={() => setImageError(false)}
              />
            </div>
            
            <div className="absolute flex justify-start top-0 text-danger text-[24px] font-bold w-full p-4">
              {/*{videoDimensions.height} p*/}
              <img className="w-[7%] md:w-[5%] h-auto" src={BENTOHUB_LOGO}/>
            </div>

            {
              isChapterListOpen?
                <div 
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="absolute top-0 z-[100] flex flex-col p-4 gap-2 w-full h-full overflow-auto text-white bg-[#000000]/90">
                  <div className="flex gap-4 items-center pb-2 border-b border-dirty-white">
                    <button onClick={toggleTimeChapterList}>
                      <IoIosArrowBack />
                    </button>
                    <p className="text-[14px]">Time Chapters</p>
                  </div>
                  {
                    timeChapters.map((chapter, index) => {
                      if(!chapter.title) return null
                      return (
                        <div
                          className="flex gap-2" 
                          onClick={() => alterCurrentTime(chapter.startTime)}
                        >
                          <p className="text-[14px]">{formatDuration(chapter.startTime)}</p>
                          <p className="text-[14px] text-start">{chapter.title}</p>
                        </div>
                      )
                    })
                  }
                </div>
              :
              null
            }

            {
              isSettingsOpen?
              <div 
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="flex flex-col gap-2 absolute top-0 p-4 w-full h-full z-[100] bg-[#000000]/90 overflow-hidden text-white">
                {
                  !isPlaybackRateOpen && !isClosedCaptionOpen && !isQualityOpen?
                  <>
                    <div className="flex gap-4 pb-2 items-center border-b border-dirty-white">
                      <button 
                      onClick={() => {
                        setIsSettingsOpen(!isSettingsOpen)
                        setIsPlaybackRateOpen(false)
                        setIsClosedCaptionOpen(false)
                        setIsQualityOpen(false)
                        setIsChapterListOpen(false)
                      }}
                      >
                        <IoIosArrowBack />
                      </button>
                      <p className="text-[14px]">Settings</p>
                    </div>

                    <div onClick={() => setIsPlaybackRateOpen(!isPlaybackRateOpen)} className="flex items-center justify-between w-full">
                      <div className="flex items-center gap-2">
                        <TbClockPlay />
                        <p className="text-[14px]">Playback Speed</p>
                      </div>
                      <div className="flex items-center gap-2">
                        <p className="text-[14px]">{playbackRate}</p>
                        <IoIosArrowForward />
                      </div>
                    </div>

                    <div onClick={() => setIsClosedCaptionOpen(!isClosedCaptionOpen)} className="flex items-center justify-between w-full">
                      <div className="flex items-center gap-2">
                        <FaClosedCaptioning />
                        <p className="text-[14px]">Closed Caption</p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-[14px]">{isCaptions? `${videoData.captions[captionIndex].abbreviation}` : "off"}</p>
                        <IoIosArrowForward />
                      </div>
                    </div>

                    <div onClick={() => setIsQualityOpen(!isQualityOpen)} className="flex items-center justify-between w-full">
                      <div className="flex items-center gap-2">
                        <LuSettings2 />
                        <p className="text-[14px]">Quality</p>
                      </div>
                      <div className="flex items-center">
                        <p className="text-[14px]">{videoDimensions.width > videoDimensions.height? videoDimensions.height: videoDimensions.width}</p>
                        <IoIosArrowForward />
                      </div>
                    </div>
                  </>
                  : isPlaybackRateOpen?
                  <>
                    <div className="flex gap-4 pb-2 items-center border-b border-dirty-white">
                      <button onClick={() => setIsPlaybackRateOpen(false)}><IoIosArrowBack /></button>
                      <p className="text-[14px]">Playback Speed</p>
                    </div>

                    <div className="flex flex-col gap-2 overflow-scroll">
                    {
                      playbackRateArr.map((speed, index) => {
                        return (
                          <p 
                            key={index} 
                            className={`text-[14px] ${playbackRate === speed? "text-violet font-bold" : null}`}
                            onClick={() => handlePlaybackRateChange(speed)}
                            >{speed === 1? "Normal" : speed}</p>
                        )
                      })
                    }
                    </div>
                  </>
                  : isClosedCaptionOpen?
                  <>
                    <div className="flex gap-4 pb-2 items-center border-b border-dirty-white">
                      <button onClick={() => setIsClosedCaptionOpen(false)}><IoIosArrowBack /></button>
                      <p className="text-[14px]">Closed Caption</p>
                    </div>

                    <div className="flex flex-col gap-2 overflow-scroll">
                      <p className={`text-[14px] ${!isCaptions? "text-violet font-bold" : null}`} 
                      onClick={() => {
                        hideCaptions()
                        setIsClosedCaptionOpen(false)
                      }}>off</p>
                      {
                        videoData.captions.length === 0?
                        null
                        :
                        videoData.captions.map((caption, index) => {
                          return(
                            <p className={`text-[14px] ${isCaptions && captionIndex === index? "text-violet font-bold" : null}`} 
                            onClick={() => {
                              changeCaption(index)
                              setIsClosedCaptionOpen(false)
                            }}
                            >{caption.name}</p>
                          )
                        })
                      }
                    </div>
                  </>
                  : isQualityOpen?
                  <>
                    <div className="flex gap-4 pb-2 items-center border-b border-dirty-white">
                      <button onClick={() => setIsQualityOpen(false)}><IoIosArrowBack /></button>
                      <p className="text-[14px]">Quality</p>
                    </div>

                    <div className="flex flex-col gap-2 overflow-scroll">
                      <div 
                        className={`text-[14px] ${-1 === currentLevel? "text-violet font-bold" : null}`} 
                        onClick={() => {
                          changeResolution( -1 , "Auto")
                        }}
                      >Auto</div>
                      {
                        qualityLevels.map((quality, index) => {
                          return (
                            <div 
                              className={`text-[14px] ${quality.label === `${videoDimensions.height}p` && currentLevel !== -1? "text-violet font-bold" : null}`} 
                              onClick={() => {
                                changeResolution(quality.value, quality.label, quality.url)
                              }}
                            >{quality.label}</div>
                          )
                        })
                      }
                    </div>
                  </>
                  :
                  null
                }
              </div>
              :
              null
            }

            <div className={`${removeControls? "hidden" : ""} video-controls-container ${hideControls || isLoading? "hidden" : ""}`}>

                <div 
                ref={timelineContainerRef} 
                className="timeline-container" 
                // onMouseMove={(e) => handleTimelineUpdate(e, true)}
                // onMouseDown={(e) => toggleScrubbing(e, true)}
                onTouchStart={(e) => handleTimelineUpdate(e, false)}
                onTouchMove={(e) => toggleScrubbing(e, true)}
                onTouchEnd={(e) => {
                  setIsScrubbing(false)
                  toggleScrubbing(e)
                  console.log("Is it Woorking?")
                }} 
                >      
                    <div className="timeline">
                      <SeekableRange seekableRange={seekableRange} />
                      <div className={`${isSettingsOpen? "hidden" : "preview-img"} bg-[#000000] flex place-content-center`}>
                        <img
                          ref={previewImgRef} 
                          className={`justify-self-center ${imageError? "opacity-0" : "opacity-100"}`}
                          onError={() => setImageError(true)}
                          onLoad={() => setImageError(false)}
                        />
                      </div>
                      <div className="thumb-indicator"></div>
                    </div>

                </div>

                <div className="controls md:mx-6">
                <button className="play-pause-btn" onClick={togglePlay}>
                  {
                    isPlaying ? 
                    <FaPause className="w-5 h-5"/> 
                    : 
                    <FaPlay className="w-5 h-5"/>
                  }
                </button>
      
                <div className="volume-container relative">
                  <button onClick={() => setShowVolume(!showVolume)}>
                    { 
                      isVolumeLow? <ImVolumeLow className="w-5 h-5"/> 
                      : isVolumeHigh? <ImVolumeMedium className="w-5 h-5"/> 
                      : <ImVolumeMute2 className="w-5 h-5"/>
                    }
                  </button>
                  <div className={`${showVolume? "" : "hidden"} absolute bottom-[75px] left-[-50px] z-[100] flex items-center p-2 rounded-sm bg-[#000000]/90 rotate-[270deg]`}>
                    <input className="w-[100px]" type="range" min="0" max="1" step="any" value={volume} onChange={handleVolumeChange} />
                  </div>
                </div>

                <div className="duration-container">
                  <div className="current-time">{formatDuration(currentTime)}</div>
                  /
                  <div className="total-time">{formatDuration(totalTime)}</div>
                </div>

{/*                <button className="theater-btn" onClick={toggleTheater}>
                  {
                    isTheater? 
                    <TbRectangleFilled className="size-7" />
                    : 
                    <TbRectangle className="size-7" />
                    
                  }
                </button>*/}

                <button 
                  onClick={() => {
                    setIsSettingsOpen(!isSettingsOpen)
                    setIsPlaybackRateOpen(false)
                    setIsClosedCaptionOpen(false)
                    setIsQualityOpen(false)
                    setIsChapterListOpen(false)
                  }}
                  className="flex items-center justify-center nonSelectableText"
                >
                  <IoMdSettings className="w-7 h-7"/>
                </button>

                {/*<button className="mini-player-btn" onClick={toggleMiniPlayer}>
                  {
                    isMiniPlayer? 
                    <RiPictureInPictureExitFill className="w-7 h-7"/> 
                    : 
                    <MdPictureInPictureAlt className="w-7 h-7"/>
                  }
                </button>*/}

                {
                  timeChapters && timeChapters.length?
                  <button className="time-chapter-btn" onClick={toggleTimeChapterList}>
                    <FaListUl className="w-6 h-6" />
                  </button>
                  :
                  null
                }

                <button className="full-screen-btn" onClick={toggleFullScreen}>{isFullScreen? <RiFullscreenExitFill className="w-7 h-7" /> : <RiFullscreenFill  className="w-7 h-7"/>}</button>

                </div>
            </div>
            <video 
            ref={videoRef}
            onLoadedData={handleLoadData}
            onVolumeChange={volumeChecker}
            onTimeUpdate={handleTimeUpdate}
            onWaiting={handleLoadingStart}
            onCanPlayThrough={handleLoadingComplete}
            onSeeking={handleSeeking}
            onProgress={handleBuffered}
            onLoadedMetadata={handleMetadataLoaded}
            muted={muted}
            autoPlay={autoplay}
            loop={loop}
            >
              <track kind="subtitles" srcLang="en" label="English" src={Subtitle} />
            </video>
        </div>
      </div>
    </div>
  )
};

export default VideoPlayer;
